import React from 'react';

import { BannerInternalInfoForm } from 'modules/shared/components';
import { useDesktopBannerContext } from 'modules/desktop-banners/providers/DesktopBannerProvider';

const DesktopBannerInternalInfoForm: React.FC = () => {
  const desktopBannerContext = useDesktopBannerContext();

  return <BannerInternalInfoForm bannerContext={desktopBannerContext} />;
};

export default DesktopBannerInternalInfoForm;
