import { useCallback } from 'react';

import {
  MobileBannerData,
  MobileBannerContainerData
} from 'modules/mobile-banners/types';
import { useGenericBannerValidate } from 'modules/shared/hooks';

type UseValidateMethods = {
  validateBannerData: (
    bannerData: MobileBannerData,
    layout: any // to-do remover esse any, problema ao dexar o layout como array
  ) => Array<string>;
};

export default function useValidate(): UseValidateMethods {
  const {
    getTitleErrors,
    getSubtitleErrors,
    getButtonErrors,
    getPublicBannerErrors,
    getBannerDataErrors
  } = useGenericBannerValidate();

  const getContainerDataErrors = useCallback(
    (containerData: MobileBannerContainerData): Array<string> => {
      const errors: Array<string> = [];
      if (containerData.backgroundType === 'normal') {
        if (
          !containerData.backgroundColor ||
          containerData.backgroundColor[0] !== '#'
        )
          errors.push('Por favor, informe uma cor para o fundo do banner');
      } else if (
        !containerData.backgroundColorLinear01 ||
        containerData.backgroundColorLinear01[0] !== '#' ||
        !containerData.backgroundColorLinear02 ||
        containerData.backgroundColorLinear02[0] !== '#'
      )
        errors.push('Por favor, informe uma cor para o fundo do banner');

      if (!containerData.elementsDirection)
        errors.push(
          'Por favor, informe uma direção para os elementos do banner'
        );
      return errors;
    },
    []
  );

  const validateBannerData = useCallback(
    (bannerData: MobileBannerData, layout: any): Array<string> => {
      const containerErrors = getContainerDataErrors(layout.container);
      const titleErrors = getTitleErrors(layout.title);
      const subtitleErrors = getSubtitleErrors(layout.subtitle);
      const buttonErrors = getButtonErrors(layout.button);
      const publicBannerErrors = getPublicBannerErrors(bannerData.public);
      const bannerDataErrors = getBannerDataErrors(bannerData);

      return [
        ...containerErrors,
        ...titleErrors,
        ...subtitleErrors,
        ...buttonErrors,
        ...publicBannerErrors,
        ...bannerDataErrors
      ];
    },
    [
      getContainerDataErrors,
      getTitleErrors,
      getSubtitleErrors,
      getButtonErrors,
      getPublicBannerErrors,
      getBannerDataErrors
    ]
  );

  return { validateBannerData };
}
