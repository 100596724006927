import React, { createContext, useContext } from 'react';
import type { ProviderPropsData } from 'modules/shared/types';
import type { UserData } from 'modules/shared/types/user-type';
import { useQuery } from '../hooks';

type UserProviderData = {
  user: UserData | null | undefined;
  loading: boolean;
};

type ContextValue = UserProviderData | null;

export const UserContext = createContext<ContextValue>(null);

const USER_DATA_TO_AUTHENTICATE = 'user_data';

const UserProvider: React.FC<ProviderPropsData> = ({ children }) => {
  const { data: user, loading } = useQuery(USER_DATA_TO_AUTHENTICATE);

  return (
    <UserContext.Provider value={{ user, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);

export default UserProvider;
