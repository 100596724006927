import { useCallback } from 'react';

import type { DesktopBannerData } from 'modules/desktop-banners/types';
import type { MobileBannerData } from 'modules/mobile-banners/types';
import type {
  BannerTitleData,
  BannerSubtitleData,
  BannerButtonData
} from 'modules/shared/types/banners';
import type { BannerPublicData } from 'modules/shared/types';

type BannerData = DesktopBannerData | MobileBannerData;

type UseValidateMethods = {
  getTitleErrors: (titleData: BannerTitleData | undefined) => Array<string>;
  getSubtitleErrors: (
    subtitleData: BannerSubtitleData | undefined
  ) => Array<string>;
  getButtonErrors: (buttonData: BannerButtonData | undefined) => Array<string>;
  getPublicBannerErrors: (publicBannerData: BannerPublicData) => Array<string>;
  getBannerDataErrors: (bannerData: BannerData) => Array<string>;
};

export default function useGenericBannerValidate(): UseValidateMethods {
  const getTitleErrors = useCallback(
    (titleData: BannerTitleData | undefined): Array<string> => {
      const errors: Array<string> = [];
      if (!titleData) return errors;

      if (!titleData.fontColor || titleData.fontColor[0] !== '#')
        errors.push('Cor da fonte do texto principal é obrigatório');
      if (!titleData.text)
        errors.push('Texto do texto principal é obrigatório');

      return errors;
    },
    []
  );

  const getSubtitleErrors = useCallback(
    (subtitleData: BannerSubtitleData | undefined): Array<string> => {
      const errors: Array<string> = [];
      if (!subtitleData) return errors;

      if (!subtitleData.fontColor || subtitleData.fontColor[0] !== '#')
        errors.push('Cor da fonte do texto secundário é obrigatório');
      if (!subtitleData.text)
        errors.push('Texto do texto secundário é obrigatório');

      return errors;
    },
    []
  );

  const getButtonErrors = useCallback(
    (buttonData: BannerButtonData | undefined): Array<string> => {
      const errors: Array<string> = [];
      if (!buttonData) return errors;

      if (!buttonData.backgroundColor || buttonData.backgroundColor[0] !== '#')
        errors.push('Cor de fundo do botão é obrigatório');
      if (!buttonData.fontColor || buttonData.fontColor[0] !== '#')
        errors.push('Cor da fonte do botão é obrigatório');
      if (!buttonData.text) errors.push('Texto do botão é obrigatório');

      return errors;
    },
    []
  );

  const getPublicBannerErrors = useCallback(
    (publicBannerData: BannerPublicData): Array<string> => {
      const errors: Array<string> = [];

      if (publicBannerData.universities.type === 'specifics') {
        if (publicBannerData.universities.ids?.length === 0)
          errors.push(
            'Obrigatório informar as universidades que vão aparecer no banner'
          );
      }

      return errors;
    },
    []
  );

  const getBannerDataErrors = useCallback(
    (bannerData: BannerData): Array<string> => {
      const errors: Array<string> = [];

      if (!bannerData.name) errors.push('Nome do banner é obrigatório');
      if (!bannerData.initialDate)
        errors.push('Data inicial do banner é obrigatório');
      if (!bannerData.endDate)
        errors.push('Data final do banner é obrigatório');

      return errors;
    },
    []
  );

  return {
    getTitleErrors,
    getSubtitleErrors,
    getButtonErrors,
    getPublicBannerErrors,
    getBannerDataErrors
  };
}
