const colors = {
  mainOrange: '#F9AC3E',
  warningOrangeColor: '#F7C602',
  warningRedColor: '#C63100',
  greenWarningColor: '#339901',
  defaultBannerBackgroundColor: '#CCC',
  defaultBannerBackgroundColorB: '#777'
};

export default colors;
