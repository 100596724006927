import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { useMobileBannerContext } from 'modules/mobile-banners/providers/MobileBannerProvider';

const SaveBannerButton: React.FC = () => {
  const mobileBannerContext = useMobileBannerContext();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onCreateBannerClick = useCallback(async () => {
    if (loading) return;

    setLoading(true);
    const success = await mobileBannerContext?.saveBanner();
    if (success) {
      alert('Banner salvo com sucesso.');
      history.push('/mobile-banner');
      return;
    }
    setLoading(false);
  }, [mobileBannerContext, loading]);

  return (
    <Container onClick={onCreateBannerClick}>
      {loading ? 'Salvando...' : 'Salvar banner'}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 50px;
  font-weight: bold;
  font-size: 1.4em;
  color: #fff;
  background-color: #000;
  width: fit-content;
  padding: 10px 15px;
  cursor: pointer;
`;

export default SaveBannerButton;
