import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

import { DesktopBannerContextValue } from 'modules/desktop-banners/providers/DesktopBannerProvider';
import { MobileBannerContextValue } from 'modules/mobile-banners/providers/MobileBannerProvider';
import { useDialogContext } from 'modules/shared/providers/DialogProvider';
import {
  DefaultDialogContainer,
  ColorInputPicker,
  HelpPopUp
} from 'modules/shared/components';

type Props = {
  bannerContext: DesktopBannerContextValue | MobileBannerContextValue;
};

const TitleDialog: React.FC<Props> = ({ bannerContext }) => {
  const [bannerLayoutName, bannerLayout] =
    bannerContext?.getCurrentBannerLayout();
  const [title, setTitle] = useState(bannerLayout?.title || DEFAULT_TITLE_DATA);

  const dialogContext = useDialogContext();

  const onUpdateInput = useCallback(
    (key, value) => {
      setTitle({ ...title, [key]: value });
    },
    [title, setTitle]
  );

  const validateTitleData = useCallback(() => {
    if (!title.text) return false;
    if (!title.fontColor || title.fontColor[0] !== '#') return false;

    return true;
  }, [title]);

  const showDeleteButton = useCallback(() => {
    return !!bannerLayout?.title;
  }, [bannerContext]);

  const onSaveTitle = useCallback(() => {
    if (!validateTitleData()) {
      alert('Form inválido');
      return;
    }

    bannerContext?.updateBannerData(bannerLayoutName, {
      ...bannerLayout,
      title
    });
    dialogContext?.unsetDialog();
  }, [validateTitleData, bannerContext, title, dialogContext]);

  const onDeleteTitle = useCallback(() => {
    bannerContext?.deleteData('title');
    dialogContext?.unsetDialog();
  }, [bannerContext, dialogContext]);

  return (
    <DefaultDialogContainer>
      <Title>Adicionar uma frase principal</Title>
      <Form>
        <CustomInput
          required
          label="Texto da frase"
          value={title.text}
          onChange={(event) => onUpdateInput('text', event.target.value)}
          variant="outlined"
        />

        <HelpPopUp />

        <Subtitle>
          Adicionar uma frase padrão para os alunos que não tem faculdade. (ex:
          Passa nas provas da Sua Faculdade!)
        </Subtitle>

        <CustomInput
          required
          label="Texto da frase"
          value={title.defaultText}
          onChange={(event) => onUpdateInput('defaultText', event.target.value)}
          variant="outlined"
        />

        <ColorInputPicker
          label="Cor do texto da frase"
          color={title.fontColor}
          onChange={(newColor) => onUpdateInput('fontColor', newColor)}
        />

        <CustomButton onClick={onSaveTitle}>Salvar</CustomButton>
        {showDeleteButton() && (
          <CustomButton onClick={onDeleteTitle}>Deletar</CustomButton>
        )}
      </Form>
    </DefaultDialogContainer>
  );
};

const DEFAULT_TITLE_DATA = {
  text: '',
  fontColor: '#000'
};

const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
`;

const Subtitle = styled.h2`
  width: 100%;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 4px;
  font-size: 1em;
  font-weight: bold;
  color: #2c2c2c;
`;

const Form = styled.div`
  width: 600px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const CustomInput = styled(TextField)`
  width: 100%;
  margin: 10px 0;
`;

const CustomButton = styled.button`
  margin: 10px 0;
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
`;

export default TitleDialog;
