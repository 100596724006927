import React from 'react';

import {
  TitleDialog,
  SubtitleDialog,
  ButtonDialog
} from 'modules/shared/dialogs/banners';
import { MobileBannerContextValue } from 'modules/mobile-banners/providers/MobileBannerProvider';

const mobileBannerElements = [
  {
    id: 'title',
    cta: 'Adicionar uma frase principal',
    defaultData: {
      title: {
        text: 'Frase principal',
        fontColor: '#ca12b2'
      }
    },
    getDialog: (bannerContext: MobileBannerContextValue): JSX.Element => (
      <TitleDialog bannerContext={bannerContext} />
    ),
    needBannerContext: true
  },
  {
    id: 'subtitle',
    cta: 'Adicionar uma frase secundária',
    defaultData: {
      subtitle: {
        text: 'Frase secundária',
        fontColor: '#ca12b2',
        size: 'normal'
      }
    },
    getDialog: (bannerContext: MobileBannerContextValue): JSX.Element => (
      <SubtitleDialog bannerContext={bannerContext} />
    ),
    needBannerContext: true
  },
  {
    id: 'button',
    cta: 'Adicionar um botão',
    defaultData: {
      button: {
        text: 'Frase do botao',
        fontColor: '#FFF',
        backgroundColor: '#ca12b2'
      }
    },
    getDialog: (bannerContext: MobileBannerContextValue): JSX.Element => (
      <ButtonDialog bannerContext={bannerContext} />
    ),
    needBannerContext: true
  }
];

export default mobileBannerElements;
