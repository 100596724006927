import styled from 'styled-components';
import { shadows } from 'modules/shared/styles';

export const Container = styled.div`
  width: 1080px;
  max-width: 95%;
  margin: 60px auto 30px auto;
  background-color: #fff;
  padding: 30px;
  border-radius: 20px;
  ${shadows.cardShadow};
`;
