import { useCallback } from 'react';

import type { DesktopBannerData } from 'modules/desktop-banners/types';
import type { MobileBannerData } from 'modules/mobile-banners/types';

type BannerData = DesktopBannerData | MobileBannerData;

type Methods = {
  formatBannerDataDate: (bannerData: BannerData) => BannerData;
};

export default function useFormatBannerDataDate(): Methods {
  const formatBannerDataDate = useCallback(
    (bannerData: BannerData): BannerData => {
      const newBannerData = { ...bannerData };
      if (
        newBannerData.initialDate &&
        typeof newBannerData.initialDate === 'string'
      ) {
        newBannerData.initialDate = new Date(newBannerData.initialDate);
      }

      if (newBannerData.endDate && typeof newBannerData.endDate === 'string') {
        newBannerData.endDate = new Date(newBannerData.endDate);
      }

      return newBannerData;
    },
    []
  );

  return {
    formatBannerDataDate
  };
}
