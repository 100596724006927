import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';

import { DesktopBannerContextValue } from 'modules/desktop-banners/providers/DesktopBannerProvider';
import { MobileBannerContextValue } from 'modules/mobile-banners/providers/MobileBannerProvider';
import { UniversitiesQueryData } from 'modules/shared/types/university-queries-type';
import { universitiesTypes } from 'modules/shared/data';
import { useQuery } from '../hooks';

type Props = {
  bannerContext: DesktopBannerContextValue | MobileBannerContextValue;
};

const GET_LIST_ALL_UNIVERSITIES = 'universities';

const BannerPublicPreview: React.FC<Props> = ({ bannerContext }) => {
  const { data: universitiesResponse } = useQuery(GET_LIST_ALL_UNIVERSITIES);

  const universities: UniversitiesQueryData = useMemo(() => {
    return universitiesResponse?.universities;
  }, [universitiesResponse]);

  const universitiesTypeName = useMemo(() => {
    const universities = bannerContext?.bannerData.public.universities;
    if (!universities?.type) return '';

    return universitiesTypes.find((t) => t.value === universities.type)?.name;
  }, [bannerContext]);

  const showTrialReason = useCallback(() => {
    const userAccess = bannerContext?.bannerData.public.userAccess;

    return userAccess?.withTrialUser && userAccess?.trialReason;
  }, [bannerContext]);

  const showOldTrialReason = useCallback(() => {
    const userAccess = bannerContext?.bannerData.public.userAccess;

    return userAccess?.withOldTrialUser && userAccess?.oldTrialReason;
  }, [bannerContext]);

  const showSpecificUniversities = useCallback(() => {
    return bannerContext?.bannerData.public.universities?.type === 'specifics';
  }, [bannerContext]);

  const getUniversityName = useCallback(
    (universityId) => {
      if (!universities) return '';

      const university = universities.find(
        (university) => university.id === universityId
      );
      if (!university) return '';

      return `${university.state} - ${university.name}`;
    },
    [universities]
  );

  const bannerPublic = useMemo(() => {
    return bannerContext?.bannerData?.public;
  }, [bannerContext?.bannerData]);

  if (!bannerPublic) return null;

  return (
    <Container>
      <Info>
        Usuários Assinantes?:{' '}
        {bannerPublic.userAccess.subscriberUser ? 'Sim' : 'Não'}
      </Info>
      <Info>
        Usuários com trial?:{' '}
        {bannerPublic.userAccess.withTrialUser ? 'Sim' : 'Não'}
      </Info>
      {showTrialReason() && (
        <Info>
          Razão do trial:{' '}
          {bannerContext?.bannerData.public.userAccess.trialReason}
        </Info>
      )}
      <Info>
        Usuários sem acesso?:{' '}
        {bannerPublic.userAccess.noAccessUser ? 'Sim' : 'Não'}
      </Info>
      <Info>Tipo de universidade: {universitiesTypeName}</Info>
      {showSpecificUniversities() && (
        <>
          {bannerContext?.bannerData.public.universities.ids?.map(
            (universityId) => (
              <UniversityName key={universityId}>
                {getUniversityName(universityId)}
              </UniversityName>
            )
          )}
        </>
      )}

      <Info>
        Excluir pessoas que ja ganhram determinado trial?:{' '}
        {bannerPublic.userAccess.withOldTrialUser ? 'Sim' : 'Não'}
      </Info>
      {showOldTrialReason() && (
        <Info>
          Razão do trial:{' '}
          {bannerContext?.bannerData.public.userAccess.oldTrialReason}
        </Info>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Info = styled.div`
  font-size: 1.1em;
  font-weight: bold;
  margin: 5px 0;
`;

const UniversityName = styled.div``;

export default BannerPublicPreview;
