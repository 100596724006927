import axios from 'axios';
import { useCallback, useState, useMemo } from 'react';
import convertObjectToCamelcase from 'modules/shared/utils/convertObjectToCamelcase';

interface QueryObject {
  query: any;
  response: {
    data: any;
    loading: boolean;
    error: string | null;
  };
}

const RA_URL = process.env.REACT_APP_RESPONDEAI_URL;

export default function useLazyQuery(): QueryObject {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const query = useCallback((url: string) => {
    setLoading(true);
    axios
      .get(`${RA_URL}/api/v3/banners/banner_maker/${url}`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((e) => {
        setError(e.response);
        setLoading(false);
      });
  }, []);

  const response = useMemo(() => {
    return {
      data: convertObjectToCamelcase(data),
      loading,
      error
    };
  }, [data, loading, error]);

  return { query, response };
}
