import React, { useMemo } from 'react';
import ListBanners from 'modules/shared/components/ListBanners';
import { useQuery } from 'modules/shared/hooks';
import { BannerQueryType } from 'modules/shared/types';

const GET_ALL_DESKTOP_BANNERS = 'banners?banner_type=desktop';

const BannersListPage: React.FC = () => {
  const { data, loading, error } = useQuery(GET_ALL_DESKTOP_BANNERS);

  const banners: BannerQueryType[] = useMemo(() => {
    return data?.banners || [];
  }, [data]);

  if (error || loading) return null;

  return (
    <ListBanners
      banners={banners}
      createBannerUrl="/desktop-banner/create"
      editBannerUrl="/desktop-banner/edit"
    />
  );
};

export default BannersListPage;
