import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { ChromePicker } from 'react-color';

type ColorInputPickerProps = {
  label: string;
  onChange: (newColor: string) => void;
  color?: string;
};

const ColorInputPicker: React.FC<ColorInputPickerProps> = ({
  label,
  color,
  onChange
}) => {
  const [open, setOpen] = useState(false);

  const onChooseColor = useCallback(
    (newColor) => {
      onChange(newColor.hex);
    },
    [onChange]
  );

  const onCloseColorPicker = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onClickInput = useCallback(
    (event) => {
      event.stopPropagation();
      setOpen(true);
    },
    [setOpen]
  );

  return (
    <>
      <Container onClick={onClickInput}>
        {label}:
        <PickedColor color={color} />
        {open && <CustomPicker color={color} onChange={onChooseColor} />}
      </Container>
      {open && <CloseButton onClick={onCloseColorPicker}>Escolher</CloseButton>}
    </>
  );
};

const Container = styled.div`
  position: relative;
  text-align: left;
  margin: 10px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
`;

const CloseButton = styled.div`
  z-index: 3;
  position: relative;
  background-color: red;
  top: 100px;
  width: 225px;
  height: 25px;
  text-align: center;
  background-color: #c9c9c9;
  border: 1px solid #222;
  cursor: pointer;
`;

const PickedColor = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => color || '#000'};
  margin: 0 10px;
`;

const CustomPicker = styled(ChromePicker)`
  position: absolute;
  z-index: 2;
`;

export default ColorInputPicker;
