import React, { useCallback } from 'react';
import styled from 'styled-components';

import type {
  BannerTitleData,
  BannerSubtitleData,
  BannerButtonData
} from 'modules/shared/types/banners';
import type {
  MobileBannerLightLayoutData,
  MobileBannerContainerData
} from 'modules/mobile-banners/types';
import { useMobileBannerContext } from 'modules/mobile-banners/providers/MobileBannerProvider';
import { useDialogContext } from 'modules/shared/providers/DialogProvider';
import phoneBackgroundImage from 'modules/mobile-banners/images/phone-simulation-background.png';
import {
  TitleDialog,
  SubtitleDialog,
  ButtonDialog
} from 'modules/shared/dialogs/banners';
import { ContainerDialog } from 'modules/mobile-banners/dialogs';

type Props = {
  bannerData?: MobileBannerLightLayoutData;
  disableClick?: boolean;
};

const BannerPreview: React.FC<Props> = ({ bannerData, disableClick }) => {
  const dialogContext = useDialogContext();
  const mobileBannerContext = useMobileBannerContext();

  const [, bannerLayout] = mobileBannerContext?.getCurrentBannerLayout();

  const onBannerContainerClick = useCallback(() => {
    if (disableClick) return;

    dialogContext?.setDialog(<ContainerDialog />);
  }, [dialogContext, mobileBannerContext]);

  const onBannerTitleClick = useCallback(
    (event) => {
      if (disableClick) return;

      event.stopPropagation();
      dialogContext?.setDialog(
        <TitleDialog bannerContext={mobileBannerContext} />
      );
    },
    [dialogContext]
  );

  const onBannerSubtitleClick = useCallback(
    (event) => {
      if (disableClick) return;

      event.stopPropagation();
      dialogContext?.setDialog(
        <SubtitleDialog bannerContext={mobileBannerContext} />
      );
    },
    [dialogContext, disableClick]
  );

  const onBannerButtonClick = useCallback(
    (event) => {
      if (disableClick) return;

      event.stopPropagation();
      dialogContext?.setDialog(
        <ButtonDialog bannerContext={mobileBannerContext} />
      );
    },
    [dialogContext, disableClick]
  );

  let banner = bannerLayout;
  if (!banner) return null;
  if (banner) banner = { ...banner, ...bannerData };

  return (
    <PhoneBackground>
      <Container {...banner.container} onClick={onBannerContainerClick}>
        <Content direction={banner.container.elementsDirection}>
          <TextContainer>
            {banner.title && (
              <TextTitle {...banner.title} onClick={onBannerTitleClick}>
                {banner.title.text}
              </TextTitle>
            )}
            {banner.subtitle && (
              <TextSubtitle
                {...banner.subtitle}
                onClick={onBannerSubtitleClick}
              >
                {banner.subtitle.text}
              </TextSubtitle>
            )}
          </TextContainer>
          {banner.button && (
            <Button {...banner.button} onClick={onBannerButtonClick}>
              {banner.button.text}
            </Button>
          )}
        </Content>
      </Container>
    </PhoneBackground>
  );
};

export default BannerPreview;

const PhoneBackground = styled.div`
  position: relative;
  width: 350px;
  max-width: 100%;
  height: 550px;
  margin: auto;
  background-image: url(${phoneBackgroundImage});
  background-size: cover;
`;

const Container = styled.div<MobileBannerContainerData>`
  position: absolute; /* need to be position: fixed, on real react component */
  display: flex;
  align-items: center;
  bottom: 0;
  width: 100%;
  min-height: 60px; /* need to be height: 100%, on real react component */
  overflow: hidden;
  ${({
    backgroundType,
    backgroundColor,
    backgroundColorLinear01,
    backgroundColorLinear02
  }) => {
    if (backgroundType === 'normal')
      return `background-color: ${backgroundColor};`;
    return `background-image: linear-gradient(to right, ${backgroundColorLinear01}, ${backgroundColorLinear02});`;
  }}
  cursor: pointer;
`;

const Content = styled.div<{ direction: string }>`
  position: relative; /* not necessary, on real react component */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  ${({ direction }) => {
    if (direction === 'horizontal') return 'flex-direction: row;';

    return 'flex-direction: column;';
  }}

  padding: 5px 0;
  width: 100%;
  height: 100%;
  margin: auto;
`;

const TextContainer = styled.div`
  padding: 3px 0;
`;

const TextTitle = styled.h1<BannerTitleData>`
  font-weight: bold;
  text-align: center;
  color: ${({ fontColor }) => fontColor};
`;

const TextSubtitle = styled.h1<BannerSubtitleData>`
  font-size: ${({ size }) => (size === 'big' ? '1em' : '.9em')};
  font-weight: bold;
  text-align: center;
  color: ${({ fontColor }) => fontColor};
`;

const Button = styled.div<BannerButtonData>`
  max-width: 150px;
  padding: 8px 10px;
  background-color: ${({ backgroundColor }) => backgroundColor || '#000'};
  color: ${({ fontColor }) => fontColor || '#FFF'};
  border-radius: 30px;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  text-align: center;
  font-size: 0.8em;
`;
