import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { colors } from 'modules/shared/styles';
import { usePostRequest } from '../hooks';

type BannerData = {
  id: number;
  name: string;
};

const Banner: React.FC<{ data: BannerData; editBannerUrl: string }> = ({
  data,
  editBannerUrl
}) => {
  const { postQuery: deleteBannerReq } = usePostRequest(`delete/${data.id}`);

  const history = useHistory();

  const onEditClick = useCallback(() => {
    history.push(`${editBannerUrl}/${data.id}`);
  }, []);

  const onDeleteClick = useCallback(async () => {
    try {
      await deleteBannerReq();
      alert('Banner excluido com sucesso!');
      window.location.reload();
    } catch (err: any) {
      // como remover esse any ?
      alert(err.response.data.message);
    }
  }, []);

  return (
    <Container>
      <Name>{data.name}</Name>
      <ButtonsContainer>
        <CustomIcon
          icon={faEdit}
          color={colors.warningOrangeColor}
          onClick={onEditClick}
        />
        <CustomIcon
          icon={faTrash}
          color={colors.warningRedColor}
          onClick={onDeleteClick}
        />
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0 20px;
  border: 1px solid black;
`;

const Name = styled.div`
  font-weight: bold;
`;

const ButtonsContainer = styled.div``;

const CustomIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${({ color }) => color};
  margin: 0 5px;
`;

export default Banner;
