import axios, { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import convertObjectToCamelcase from 'modules/shared/utils/convertObjectToCamelcase';

interface QueryObject {
  data: any;
  loading: boolean;
  error: string | null;
}

const RA_URL = process.env.REACT_APP_RESPONDEAI_URL;

export default function useQuery(url: string): QueryObject {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = Cookies.get('banner_session') as string;
  const config: AxiosRequestConfig = {
    headers: {
      'Banner-Maker-Session': token
    }
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${RA_URL}/api/v3/banners/banner_maker/${url}`, config)
      .then((response) => {
        setData(convertObjectToCamelcase(response.data));
        setLoading(false);
      })
      .catch((e) => {
        setError(e.response);
        setLoading(false);
      });
  }, [url]);

  return { data, loading, error };
}
