import React from 'react';

import { BannerPublicPreview } from 'modules/shared/components';
import { useDesktopBannerContext } from 'modules/desktop-banners/providers/DesktopBannerProvider';

const DesktopBannerPublicPreview: React.FC = () => {
  const desktopBannerContext = useDesktopBannerContext();

  return <BannerPublicPreview bannerContext={desktopBannerContext} />;
};

export default DesktopBannerPublicPreview;
