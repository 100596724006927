import React from 'react';

import { BannerPublicPreview } from 'modules/shared/components';
import { useMobileBannerContext } from 'modules/mobile-banners/providers/MobileBannerProvider';

const MobileBannerPublicPreview: React.FC = () => {
  const mobileBannerContext = useMobileBannerContext();

  return <BannerPublicPreview bannerContext={mobileBannerContext} />;
};

export default MobileBannerPublicPreview;
