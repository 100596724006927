import React from 'react';
import styled from 'styled-components';
import { DesktopBannerContextValue } from 'modules/desktop-banners/providers/DesktopBannerProvider';
import { MobileBannerContextValue } from 'modules/mobile-banners/providers/MobileBannerProvider';

type Props = {
  bannerContext: DesktopBannerContextValue | MobileBannerContextValue;
};

const SelectBanner: React.FC<Props> = ({ bannerContext }) => {
  const [currentBannerLayout] = bannerContext?.getCurrentBannerLayout();

  const handleSelectClick = (value: string) => {
    bannerContext?.updateCurrentBannerLayout(value);
  };

  return (
    <SelectContainer>
      <SelectButton
        className={currentBannerLayout === 'layoutA' ? 'Selected' : undefined}
        onClick={() => handleSelectClick('layoutA')}
      >
        LayoutA
      </SelectButton>
      <SelectButton
        className={currentBannerLayout === 'layoutA' ? undefined : 'Selected'}
        onClick={() => handleSelectClick('layoutB')}
      >
        LayoutB
      </SelectButton>
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px;
`;

const SelectButton = styled.div.attrs((props) => ({
  className: props.className
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #111;
  user-select: none;
  border-radius: 25px;
  height: 40px;
  width: 140px;
  background-color: #ccc;
  margin: 10px;
  &.Selected {
    color: #495e35;
    border: 2px solid #495e35;
  }
`;

export default SelectBanner;
