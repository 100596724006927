import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect
} from 'react';
import { useParams } from 'react-router-dom';

import { colors } from 'modules/shared/styles';
import type {
  MobileBannerContextData,
  MobileBannerData
} from 'modules/mobile-banners/types';
import type { ProviderPropsData, EditRouteParam } from 'modules/shared/types';
import { useValidate } from 'modules/mobile-banners/hooks';
import {
  useFormatBannerDataDate,
  useLazyQuery,
  usePostRequest
} from 'modules/shared/hooks';

export type MobileBannerContextValue = MobileBannerContextData | null;

const MobileBannerContext = createContext<MobileBannerContextValue>(null);

const MobileBannerProvider: React.FC<ProviderPropsData> = ({ children }) => {
  const [bannerData, setBannerData] = useState(INITIAL_BANNER_DATA);
  const [currentBannerLayout, setCurrentBannerLayout] = useState('layoutA');
  const { validateBannerData } = useValidate();

  const { query: loadBannerReq, response } = useLazyQuery();

  const { postQuery: createOrEditBannerReq } = usePostRequest(
    `create_or_edit_banner`
  );

  const { formatBannerDataDate } = useFormatBannerDataDate();
  const { bannerId } = useParams<EditRouteParam>();

  useEffect(() => {
    if (!bannerId) return;
    const GET_BANNER_FULL_DATA_BY_ID = `${bannerId}`;

    loadBannerReq(GET_BANNER_FULL_DATA_BY_ID);
  }, [bannerId, loadBannerReq]);

  useEffect(() => {
    if (response.loading) return;
    const { data } = response;

    const newBannerData = formatBannerDataDate({ ...data }) as MobileBannerData;
    setBannerData(newBannerData);
  }, [response]);

  const updateBannerData = useCallback(
    (key, value) => {
      setBannerData({ ...bannerData, [key]: value });
    },
    [bannerData, setBannerData]
  );

  const deleteData = useCallback(
    (key: keyof MobileBannerData) => {
      const newBannerData = { ...bannerData };
      delete newBannerData[key];
      setBannerData(newBannerData);
    },
    [bannerData, setBannerData]
  );

  const saveBanner = useCallback(async () => {
    const [, layout] = getCurrentBannerLayout();
    const errors = validateBannerData(bannerData, layout);
    if (errors.length > 0) {
      alert(errors.join('.\n'));
      return null;
    }
    try {
      await createOrEditBannerReq({
        ...bannerData,
        initialDate: bannerData.initialDate?.toUTCString(),
        endDate: bannerData.endDate?.toUTCString(),
        bannerId: bannerId || null,
        bannerType: 'mobile'
      });
      return true;
    } catch (e: any) {
      alert(e.response.data.message);
      return false;
    }
  }, [bannerData, createOrEditBannerReq, validateBannerData]);

  const getCurrentBannerLayout = useCallback(() => {
    if (currentBannerLayout === 'layoutA') {
      return ['layoutA', bannerData.layoutA];
    }
    return ['layoutB', bannerData.layoutB];
  }, [bannerData, currentBannerLayout]);

  const updateCurrentBannerLayout = useCallback(
    (value) => {
      setCurrentBannerLayout(value);
    },
    [bannerData, currentBannerLayout]
  );
  const updateWinnerBanner = useCallback(
    (value) => {
      if (bannerData.testWinner === value) {
        updateBannerData('testWinner', null);
      } else {
        updateBannerData('testWinner', value);
      }
    },
    [bannerData]
  );

  return (
    <MobileBannerContext.Provider
      value={{
        bannerData,
        updateBannerData,
        deleteData,
        saveBanner,
        getCurrentBannerLayout,
        updateCurrentBannerLayout,
        updateWinnerBanner
      }}
    >
      {children}
    </MobileBannerContext.Provider>
  );
};

const INITIAL_BANNER_DATA = {
  layoutA: {
    container: {
      backgroundType: 'normal',
      backgroundColor: colors.defaultBannerBackgroundColor,
      elementsDirection: 'horizontal'
    }
  },
  layoutB: {
    container: {
      backgroundType: 'normal',
      backgroundColor: colors.defaultBannerBackgroundColorB,
      elementsDirection: 'horizontal'
    }
  },
  hasTestAB: false,
  testWinner: null,
  public: {
    userAccess: {
      subscriberUser: true,
      withTrialUser: true,
      noAccessUser: true,
      withOldTrialUser: false
    },
    universities: {
      type: 'all_universities'
    }
  },
  name: '',
  initialDate: new Date(),
  endDate: new Date()
} as MobileBannerData;

export const useMobileBannerContext = () => useContext(MobileBannerContext);

export default MobileBannerProvider;
