import React from 'react';

import { EditBannerPublicDialog } from 'modules/shared/dialogs/banners';
import { useDesktopBannerContext } from 'modules/desktop-banners/providers/DesktopBannerProvider';

const EditDesktopPublicDialog: React.FC = () => {
  const desktopBannerContext = useDesktopBannerContext();

  return <EditBannerPublicDialog bannerContext={desktopBannerContext} />;
};

export default EditDesktopPublicDialog;
