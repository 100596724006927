import React, { useCallback } from 'react';
import styled from 'styled-components';
import { BannerQueryType } from 'modules/shared/types';
import { Banner, AddBannerButton, Container } from 'modules/shared/components';
import { useHistory } from 'react-router-dom';

type ListBannersProps = {
  banners: Array<BannerQueryType>;
  createBannerUrl: string;
  editBannerUrl: string;
};

const ListBanners: React.FC<ListBannersProps> = ({
  banners,
  createBannerUrl,
  editBannerUrl
}) => {
  const history = useHistory();
  const onClickCreateBanner = useCallback(() => {
    history.push(createBannerUrl);
  }, []);

  return (
    <Container>
      <Title>Lista de banners</Title>
      <ListContainer>
        {banners.map((banner) => (
          <Banner key={banner.id} data={banner} editBannerUrl={editBannerUrl} />
        ))}
      </ListContainer>
      <AddBannerButton onClick={onClickCreateBanner} />
    </Container>
  );
};

const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
`;

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
`;

export default ListBanners;
