import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

const HelpPopUp: React.FC = () => {
  const [popUpOpen, setPopUpOpen] = useState(false);
  return (
    <>
      <HelperContainer onClick={() => setPopUpOpen(!popUpOpen)}>
        <HelperButton icon={faQuestionCircle} />
        <p>
          Clique aqui para obter ajuda sobre como colocar textos customizados
        </p>
      </HelperContainer>
      {popUpOpen && (
        <PopUpOpacityBackground>
          <PopUpMessage>
            <CloseButton
              icon={faTimes}
              onClick={() => setPopUpOpen(!popUpOpen)}
            />
            <p>
              Para criar mensagens customizadas pode-se usar algumas palavras
              especificas entre colchetes, Exemplo: <br />
              <br />
              Olá, Você estuda [nx] [SiglaFaculdade] !? <br />
              <br />
              Lista de palavras-chave: <br />
              <br />
              - [nx] = na / no (depende do genero da faculdade!) <br />
              - [dx] = da / do (depende do genero da faculdade!) <br />
              - [prx] = pra / pro (depende do genero da faculdade!) <br />
              - [NomeAluno] = Primeiro nome do aluno (ex: Victor) <br />
              - [SiglaFaculdade] = Sigla da faculdade (ex: UFRJ) <br />
            </p>
          </PopUpMessage>
        </PopUpOpacityBackground>
      )}
    </>
  );
};

const HelperButton = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const HelperContainer = styled.div`
  margin-top: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #585858;
  user-select: none;
  &:hover {
    color: black;
    cursor: pointer;
  }
`;

const PopUpOpacityBackground = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
`;

const PopUpMessage = styled.div`
  background-color: white;
  padding: 10px;
  width: 70%;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  text-align: start;
  font-size: 1.1em;
`;

const CloseButton = styled(FontAwesomeIcon)`
  align-self: end;
  color: #585858;
  user-select: none;
  margin-bottom: 10px;
  font-size: 1.3em;
  &:hover {
    color: black;
    cursor: pointer;
  }
`;
export default HelpPopUp;
