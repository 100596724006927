import Cookies from 'js-cookie';
import axios, { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';
import convertObjectToCamelcase from 'modules/shared/utils/convertObjectToCamelcase';
import convertObjectToUnderscore from 'modules/shared/utils/convertObjectToUnderscore';

interface QueryObject {
  postQuery: any; // TODO remove any
}

const RA_URL = process.env.REACT_APP_RESPONDEAI_URL;

export default function usePostRequest(url: string): QueryObject {
  const token = Cookies.get('banner_session') as string;
  const config: AxiosRequestConfig = {
    headers: {
      'Banner-Maker-Session': token
    }
  };
  const postQuery = useCallback(
    async (data = {}) => {
      const response = await axios.post(
        `${RA_URL}/api/v3/banners/banner_maker/${url}`,
        convertObjectToUnderscore(data),
        config
      );
      return {
        data: convertObjectToCamelcase(response.data || {})
      };
    },
    [url]
  );

  return { postQuery };
}
