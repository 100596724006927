import React from 'react';

import { StripDialog } from 'modules/desktop-banners/dialogs';
import {
  TitleDialog,
  SubtitleDialog,
  ButtonDialog
} from 'modules/shared/dialogs/banners';
import { DesktopBannerContextValue } from 'modules/desktop-banners/providers/DesktopBannerProvider';

const desktopBannerElements = [
  {
    id: 'strip',
    cta: 'Adicionar uma faixa',
    defaultData: {
      strip: {
        text: 'Faixa',
        fontColor: '#FFF',
        backgroundColor: '#ca12b2'
      }
    },
    getDialog: (): JSX.Element => <StripDialog />
  },
  {
    id: 'title',
    cta: 'Adicionar uma frase principal',
    defaultData: {
      title: {
        text: 'Frase principal',
        fontColor: '#ca12b2'
      }
    },
    getDialog: (bannerContext: DesktopBannerContextValue): JSX.Element => (
      <TitleDialog bannerContext={bannerContext} />
    ),
    needBannerContext: true
  },
  {
    id: 'subtitle',
    cta: 'Adicionar uma frase secundária',
    defaultData: {
      subtitle: {
        text: 'Frase secundária',
        fontColor: '#ca12b2',
        size: 'normal'
      }
    },
    getDialog: (bannerContext: DesktopBannerContextValue): JSX.Element => (
      <SubtitleDialog bannerContext={bannerContext} />
    ),
    needBannerContext: true
  },
  {
    id: 'button',
    cta: 'Adicionar um botão',
    defaultData: {
      button: {
        text: 'Frase do botao',
        fontColor: '#FFF',
        backgroundColor: '#ca12b2'
      }
    },
    getDialog: (bannerContext: DesktopBannerContextValue): JSX.Element => (
      <ButtonDialog bannerContext={bannerContext} />
    ),
    needBannerContext: true
  }
];

export default desktopBannerElements;
