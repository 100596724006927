import React from 'react';
import { DesktopBannerProvider } from 'modules/desktop-banners/providers';
import CreateAndEditBannerForm from './CreateAndEditBannerForm';

const CreateAndEditBannerPage: React.FC = () => {
  return (
    <DesktopBannerProvider>
      <CreateAndEditBannerForm />
    </DesktopBannerProvider>
  );
};

export default CreateAndEditBannerPage;
