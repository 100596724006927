import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { DesktopBannerContextValue } from 'modules/desktop-banners/providers/DesktopBannerProvider';
import { MobileBannerContextValue } from 'modules/mobile-banners/providers/MobileBannerProvider';

type Props = {
  bannerContext: DesktopBannerContextValue | MobileBannerContextValue;
};

const SelectWinner: React.FC<Props> = ({ bannerContext }) => {
  const isLayoutAWinner = useMemo(() => {
    return bannerContext?.bannerData.testWinner === 'A';
  }, [bannerContext]);
  const isLayoutBWinner = useMemo(() => {
    return bannerContext?.bannerData.testWinner === 'B';
  }, [bannerContext]);

  const handleTestWinner = useCallback(
    (banner) => {
      bannerContext?.updateWinnerBanner(banner);
    },
    [bannerContext]
  );
  return (
    <CheckBoxesContainer>
      <CheckBox
        checked={isLayoutAWinner}
        onChange={() => handleTestWinner('A')}
      />
      <Label>Layout A</Label>
      <CheckBox
        checked={isLayoutBWinner}
        onChange={() => handleTestWinner('B')}
      />
      <Label>Layout B</Label>
    </CheckBoxesContainer>
  );
};

const CheckBoxesContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 12px;
`;

const Label = styled.p`
  font-size: 1.1em;
  margin-right: 20px;
  font-weight: bold;
`;

export default SelectWinner;
