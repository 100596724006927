import React from 'react';
import { MobileBannerProvider } from 'modules/mobile-banners/providers';
import CreateAndEditBannerForm from './CreateAndEditBannerForm';

const CreateAndEditBannerPage: React.FC = () => {
  return (
    <MobileBannerProvider>
      <CreateAndEditBannerForm />
    </MobileBannerProvider>
  );
};

export default CreateAndEditBannerPage;
