import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { BaseDialog } from 'modules/shared/dialogs';
import {
  AddElementDialog,
  EditMobileBannerPublicDialog
} from 'modules/mobile-banners/dialogs';
import {
  MobileBannerInternalInfoForm,
  BannerPreview,
  MobileBannerPublicPreview,
  SaveBannerButton
} from 'modules/mobile-banners/components';
import {
  Container,
  AddElementToBannerButton,
  UpdateBannerPublicButton,
  TestAbForm,
  SelectBanner,
  SelectWinner
} from 'modules/shared/components';
import { useDialogContext } from 'modules/shared/providers/DialogProvider';
import { useMobileBannerContext } from '../providers/MobileBannerProvider';

const CreateAndEditBannerPage: React.FC = () => {
  const dialogContext = useDialogContext();
  const mobileBannerContext = useMobileBannerContext();

  const openAddElementDialog = useCallback(() => {
    dialogContext?.setDialog(<AddElementDialog />);
  }, [dialogContext]);

  const openEditBannerPublicDialog = useCallback(() => {
    dialogContext?.setDialog(<EditMobileBannerPublicDialog />);
  }, [dialogContext]);

  const onChangeTestAbForm = useCallback(
    (value: boolean) => {
      if (!value) {
        const [layout] = mobileBannerContext?.getCurrentBannerLayout();
        if (layout === 'layoutB') {
          mobileBannerContext?.updateCurrentBannerLayout('layoutA');
        }
      }
      mobileBannerContext?.updateBannerData('hasTestAB', value);
    },
    [mobileBannerContext]
  );

  const hasAbTest = useMemo(() => {
    return mobileBannerContext?.bannerData.hasTestAB;
  }, [mobileBannerContext?.bannerData]);

  return (
    <>
      <Container>
        <Title>Informações do banner</Title>
        <MobileBannerInternalInfoForm />

        <TestAbForm
          checked={hasAbTest}
          onChangeAbCheckBox={onChangeTestAbForm}
        />

        {hasAbTest && <SelectBanner bannerContext={mobileBannerContext} />}
        <Title>Layout do banner</Title>
        <BannerPreview />
        <AddElementToBannerButton onClick={openAddElementDialog} />

        {hasAbTest && (
          <>
            <Title marginTop="40px">
              Já temos um vencedor do teste ? Qual ?
            </Title>
            <SelectWinner bannerContext={mobileBannerContext} />
          </>
        )}

        <Title marginTop="40px">Publico do banner</Title>
        <MobileBannerPublicPreview />
        <UpdateBannerPublicButton onClick={openEditBannerPublicDialog} />

        <SaveBannerButton />
      </Container>
      <BaseDialog />
    </>
  );
};

type TitleData = {
  marginTop?: string;
};

const Title = styled.h1<TitleData>`
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 40px;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}
`;

export default CreateAndEditBannerPage;
