import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { BaseDialog } from 'modules/shared/dialogs';
import {
  BannerPreview,
  DesktopBannerInternalInfoForm,
  SaveBannerButton,
  DesktopBannerPublicPreview
} from 'modules/desktop-banners/components';
import {
  Container,
  AddElementToBannerButton,
  UpdateBannerPublicButton,
  TestAbForm,
  SelectBanner,
  SelectWinner
} from 'modules/shared/components';
import {
  AddElementDialog,
  EditDesktopBannerPublicDialog
} from 'modules/desktop-banners/dialogs';
import { useDialogContext } from 'modules/shared/providers/DialogProvider';
import { useDesktopBannerContext } from '../providers/DesktopBannerProvider';

const CreateAndEditBannerForm: React.FC = () => {
  const dialogContext = useDialogContext();
  const desktopBannerContext = useDesktopBannerContext();

  const openAddElementDialog = useCallback(() => {
    dialogContext?.setDialog(<AddElementDialog />);
  }, [dialogContext]);

  const openEditBannerPublicDialog = useCallback(() => {
    dialogContext?.setDialog(<EditDesktopBannerPublicDialog />);
  }, [dialogContext]);

  const onChangeTestAbForm = useCallback(
    (value: boolean) => {
      if (!value) {
        const [layout] = desktopBannerContext?.getCurrentBannerLayout();
        if (layout === 'layoutB') {
          desktopBannerContext?.updateCurrentBannerLayout('layoutA');
        }
      }
      desktopBannerContext?.updateBannerData('hasTestAB', value);
    },
    [desktopBannerContext]
  );

  const hasTestAB = useMemo(() => {
    return desktopBannerContext?.bannerData.hasTestAB;
  }, [desktopBannerContext]);

  return (
    <>
      <Container>
        <Title>Informações do banner</Title>
        <DesktopBannerInternalInfoForm />
        <TestAbForm
          checked={hasTestAB}
          onChangeAbCheckBox={onChangeTestAbForm}
        />
        {hasTestAB && <SelectBanner bannerContext={desktopBannerContext} />}

        <Title>Layout do banner</Title>
        <BannerPreview />
        <AddElementToBannerButton onClick={() => openAddElementDialog()} />

        {hasTestAB && (
          <>
            <Title marginTop="40px">
              Já temos um vencedor do teste ? Qual ?
            </Title>
            <SelectWinner bannerContext={desktopBannerContext} />
          </>
        )}

        <Title marginTop="40px">Publico do banner</Title>
        <DesktopBannerPublicPreview />
        <UpdateBannerPublicButton onClick={openEditBannerPublicDialog} />

        <SaveBannerButton />
      </Container>
      <BaseDialog />
    </>
  );
};

type TitleData = {
  marginTop?: string;
};

const Title = styled.h1<TitleData>`
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 40px;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}
`;

export default CreateAndEditBannerForm;
