import styled from 'styled-components';
import React from 'react';

type TestAbFormParams = {
  onChangeAbCheckBox: (value: boolean) => void;
  checked: boolean | undefined;
};

const TestAbForm: React.FC<TestAbFormParams> = ({
  onChangeAbCheckBox,
  checked
}) => {
  return (
    <Container>
      <CheckBox
        checked={checked}
        onChange={(e) => onChangeAbCheckBox(e.target.checked)}
      />
      <Label>Teremos teste ab ?</Label>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 15px;
`;

const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 12px;
`;

const Label = styled.span`
  font-size: 1.1em;
  font-weight: bold;
`;

export default TestAbForm;
