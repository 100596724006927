import React, { useState, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import {
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { MultiSelect } from 'react-multi-select-component';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';

import { DesktopBannerContextValue } from 'modules/desktop-banners/providers/DesktopBannerProvider';
import { MobileBannerContextValue } from 'modules/mobile-banners/providers/MobileBannerProvider';
import { useDialogContext } from 'modules/shared/providers/DialogProvider';
import { DefaultDialogContainer } from 'modules/shared/components';
import { BannerPublicData } from 'modules/shared/types';
import { universitiesTypes } from 'modules/shared/data';
import { UniversitiesQueryData } from 'modules/shared/types/university-queries-type';
import { useQuery } from 'modules/shared/hooks';

type Props = {
  bannerContext: DesktopBannerContextValue | MobileBannerContextValue;
};

const GET_LIST_ALL_UNIVERSITIES = 'universities';

const EditBannerPublicDialog: React.FC<Props> = ({ bannerContext }) => {
  const dialogContext = useDialogContext();
  const [publicBanner, setPublicBanner] = useState(
    bannerContext?.bannerData?.public
  );
  const [selectedUniversities, setSelectedUniversities] = useState<
    Array<Option>
  >([]);

  const { data: universitiesResponse } = useQuery(GET_LIST_ALL_UNIVERSITIES);

  const universities: UniversitiesQueryData = useMemo(() => {
    return universitiesResponse?.universities;
  }, [universitiesResponse]);

  useEffect(() => {
    const initialUniversityIds =
      bannerContext?.bannerData.public.universities.ids;
    if (!initialUniversityIds) return;
    if (!universities) return;

    const selected = universities
      .filter((university) => initialUniversityIds.includes(university.id))
      .map((university) => {
        return {
          label: `${university.state} - ${university.name}`,
          value: university.id
        };
      });
    setSelectedUniversities(selected);
  }, [bannerContext]);

  useEffect(() => {
    if (selectedUniversities.length === 0) return;
    if (publicBanner?.universities.type !== 'specifics') return;

    const universitiesIds = selectedUniversities.map(
      (option: any) => option.value
    );
    const newUniversities = {
      ...publicBanner?.universities,
      ids: universitiesIds
    };
    const newPublicBanner = {
      ...publicBanner,
      universities: newUniversities
    } as BannerPublicData;
    setPublicBanner(newPublicBanner);
  }, [selectedUniversities]);

  const onUpdateUserAccess = useCallback(
    (key, value) => {
      const newUserAccess = { ...publicBanner?.userAccess, [key]: value };
      const newPublicBanner = {
        ...publicBanner,
        userAccess: newUserAccess
      } as BannerPublicData;
      setPublicBanner(newPublicBanner);
    },
    [publicBanner, setPublicBanner]
  );

  const onUpdateUniversities = useCallback(
    (key, value) => {
      const newUniversities = { ...publicBanner?.universities, [key]: value };
      const newPublicBanner = {
        ...publicBanner,
        universities: newUniversities
      } as BannerPublicData;
      setPublicBanner(newPublicBanner);
    },
    [publicBanner, setPublicBanner]
  );

  const showTrialReasonInput = useCallback(() => {
    return publicBanner?.userAccess.withTrialUser;
  }, [publicBanner]);

  const showOldTrialReasonInput = useCallback(() => {
    return publicBanner?.userAccess.withOldTrialUser;
  }, [publicBanner]);

  const showUniversityMultipleInput = useCallback(() => {
    return (
      publicBanner?.universities.type === 'specifics' && universitiesResponse
    );
  }, [publicBanner, universitiesResponse]);

  const universitiesOptions = useMemo(() => {
    if (!universitiesResponse) return [];

    return universities.map((university) => {
      return {
        label: `${university.state} - ${university.name}`,
        value: university.id
      };
    });
  }, [universitiesResponse]);

  const onSaveBannerPublic = useCallback(() => {
    bannerContext?.updateBannerData('public', publicBanner);
    dialogContext?.unsetDialog();
  }, [publicBanner, selectedUniversities, bannerContext, dialogContext]);

  return (
    <DefaultDialogContainer>
      <Title>Edite o publico do banner</Title>
      <Form>
        <SelectTitle>Tipo de acesso do usuário:</SelectTitle>
        <FormControlLabel
          control={
            <Switch
              checked={publicBanner?.userAccess.subscriberUser}
              onChange={(event) =>
                onUpdateUserAccess('subscriberUser', event.target.checked)
              }
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Mostrar para assinantes?"
        />
        <FormControlLabel
          control={
            <Switch
              checked={publicBanner?.userAccess.withTrialUser}
              onChange={(event) =>
                onUpdateUserAccess('withTrialUser', event.target.checked)
              }
            />
          }
          label="Mostrar para usuários com trial?"
        />
        <FormControlLabel
          control={
            <Switch
              checked={publicBanner?.userAccess.noAccessUser}
              onChange={(event) =>
                onUpdateUserAccess('noAccessUser', event.target.checked)
              }
            />
          }
          label="Mostrar para usuários sem acesso?"
        />

        {showTrialReasonInput() && (
          <>
            <CustomInput
              label="Razão do trial"
              value={publicBanner?.userAccess?.trialReason}
              onChange={(event) =>
                onUpdateUserAccess('trialReason', event.target.value)
              }
              variant="outlined"
            />
            <SmallMessage>
              Deixe vazio caso queira para qualquer trial (independente da
              razão)
            </SmallMessage>
          </>
        )}

        <SelectTitle>Universidade do usuário:</SelectTitle>
        <CustomSelect
          value={publicBanner?.universities?.type}
          onChange={(event) => onUpdateUniversities('type', event.target.value)}
        >
          {universitiesTypes.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.name}
            </MenuItem>
          ))}
        </CustomSelect>

        {showUniversityMultipleInput() && (
          <CustomMultiSelect
            options={universitiesOptions}
            value={selectedUniversities}
            onChange={(newSelectedUniversity: Array<Option>) =>
              setSelectedUniversities(newSelectedUniversity)
            }
            labelledBy="Selecionar universidades"
            overrideStrings={{
              selectSomeItems: 'Selecione as faculdades'
            }}
          />
        )}

        <SelectTitle>Usuarios para não ver o banner:</SelectTitle>

        <FormControlLabel
          control={
            <Switch
              checked={publicBanner?.userAccess.withOldTrialUser}
              onChange={(event) =>
                onUpdateUserAccess('withOldTrialUser', event.target.checked)
              }
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Excluir pessoas que ja ganhram determinado trial?"
        />
        {showOldTrialReasonInput() && (
          <>
            <CustomInput
              label="Razão do trial à ser excluido"
              value={publicBanner?.userAccess?.oldTrialReason}
              onChange={(event) =>
                onUpdateUserAccess('oldTrialReason', event.target.value)
              }
              variant="outlined"
            />
            <SmallMessage>
              É possivel colocar multiplos trials separados por virgula (sem
              espaço entre as virgulas)
              <br />
              Ex: (Trial - VAA 2023.1,Trial - VAA 2023.2)
            </SmallMessage>
          </>
        )}
        <CustomButton onClick={onSaveBannerPublic}>Salvar</CustomButton>
      </Form>
    </DefaultDialogContainer>
  );
};

const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
`;

const Form = styled.div`
  width: 600px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const CustomButton = styled.button`
  margin: 10px 0;
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
`;

const SelectTitle = styled.div`
  margin-top: 15px;
  font-size: 1.1em;
  font-weight: bold;
`;

const CustomSelect = styled(Select)`
  width: 100%;

  .MuiInput-input {
    text-align: left;
  }
`;

const CustomInput = styled(TextField)`
  width: 100%;

  &.MuiTextField-root {
    margin-top: 15px;
  }
`;

const CustomMultiSelect = styled(MultiSelect)`
  width: 100%;
  margin-top: 15px;
`;

const SmallMessage = styled.div`
  font-size: 0.7em;
  font-weight: bold;
  margin-top: 3px;
`;

export default EditBannerPublicDialog;
